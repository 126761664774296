import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import Carousel from './Carousel';
import { sortDescendingPublishedDate } from '../../utils/sort';

const propTypes = {
  carouselStepTime: PropTypes.number,
};
const defaultProps = {
  carouselStepTime: 5000,
};

/**
 * Hook for using intervals
 *
 * @param deps - object from useState, don't use non-object, because on double clear it will stop interval
 * @param {function} handler
 * @param {number} interval
 */
const useInterval = ({ handler, interval, deps }) => {
  const [intervalId, setIntervalId] = useState();

  const clearCb = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    const id = setInterval(handler, interval);
    setIntervalId(id);
    return () => clearInterval(id);
    // Do not update those dependencies
    // eslint-disable-next-line
  }, [deps]);

  return {
    clearCb,
  };
};

const useArticlesData = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulArticle(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            id
            title
            image {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
            updatedAt
            slug
            publishedDate
            subtitle {
              subtitle
            }
            isFeatured
          }
        }
      }
    }
  `);

  const articlesData = data.allContentfulArticle.edges.map(({ node }) => node);

  return articlesData
    .filter(({ isFeatured }) => Boolean(isFeatured))
    .sort(sortDescendingPublishedDate)
    .slice(0, 3)
    .map(({ image, slug, publishedDate, title, subtitle }) => ({
      id: slug,
      title,
      description: subtitle?.subtitle,
      date: publishedDate,
      imageUrl: getImage(image),
      subTitle: subtitle?.subtitle,
    }));
};

/**
 * Carousel container with logic
 */
const CarouselContainer = ({ carouselStepTime = 5000 }) => {
  const articles = useArticlesData();

  const [currentArticleId, setArticleId] = useState({ id: articles[0].id });

  const currentArticle = articles.find(({ id }) => id === currentArticleId.id);

  const nextNewsInterval = useCallback(() => {
    const currentArticleIndex = articles.findIndex(
      (article) => article.id === currentArticleId.id
    );
    const isLast = currentArticleIndex === articles.length - 1;
    const nextArticleIndex = isLast ? 0 : currentArticleIndex + 1;
    setArticleId({ id: articles[nextArticleIndex] }.id);
  }, [articles]);

  const { clearCb } = useInterval({
    handler: nextNewsInterval,
    interval: carouselStepTime,
    deps: currentArticleId,
  });

  const handleOnChangeArticle = (articleId) => {
    clearCb();
    return setArticleId({ id: articleId });
  };

  return (
    <Carousel
      onChangeArticle={handleOnChangeArticle}
      articles={articles}
      article={currentArticle}
    />
  );
};

CarouselContainer.propTypes = propTypes;
CarouselContainer.defaultProps = defaultProps;

export default CarouselContainer;
