import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { GatsbyImage } from 'gatsby-plugin-image';
import Clock from '@icons/clock-grey.svg';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';

import styles from './NewsElement.styles';

const NewsElement = ({ id, date, title, headerImageUrl, firstSection }) => {
  const {
    StyledNewsElement,
    StyledNewsImg,
    StyledNewsLink,
    StyledNewsTitle,
    StyledNewsText,
    StyledNewsDate,
  } = styles;

  const creationDate = moment(date).format('D MMM YYYY');

  const actionProps = {
    to: `/${id}/`,
    state: {
      noScroll: true,
    },
  };

  return (
    <StyledNewsElement>
      <StyledNewsImg {...actionProps}>
        <GatsbyImage
          image={headerImageUrl}
          objectFit="cover"
          objectPosition="center top"
          loading="lazy"
          title={title}
          alt="news"
          backgroundColor="#EEEEEE"
          style={{
            width: '100%',
            minWidth: '100%',
            height: '100%',
          }}
        />
      </StyledNewsImg>
      <div>
        <StyledNewsDate>
          <Clock /> <p>{creationDate}</p>
        </StyledNewsDate>
        <StyledNewsTitle>
          <StyledNewsLink {...actionProps}>{title}</StyledNewsLink>
        </StyledNewsTitle>
        {firstSection && (
          <StyledNewsText>
            {renderRichText(firstSection, {
              renderNode: { [BLOCKS.PARAGRAPH]: (node, children) => children },
            })}
          </StyledNewsText>
        )}
      </div>
    </StyledNewsElement>
  );
};

NewsElement.defaultProps = {
  headerImageUrl: undefined,
  sections: [],
};

NewsElement.propTypes = {
  date: PropTypes.string.isRequired,
  headerImageUrl: PropTypes.string,
  id: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string.isRequired,
};

export default NewsElement;
