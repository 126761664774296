import React, { useCallback, useMemo, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import InformationList from '@components/InformationList';
import NewsContainer from '@components/NewsContainer';
import { getFirstParagraphText } from '@utils/richText';
import CarouselContainer from '../components/Carousel/CarouselContainer';

const sectionsTypes = {
  WhatWeStandFor: 'what-we-stand-for',
  MeetTheTeam: 'meet-the-team',
};

const Home = () => {
  const data = useStaticQuery(graphql`
    {
      allJsonJson {
        edges {
          node {
            homePage {
              sections {
                id
                title
                userImportance
                list {
                  id
                  title
                  imageUrl {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                  }
                  sections {
                    type
                    value
                  }
                }
              }
            }
          }
        }
      }
      allContentfulArticle(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            publishedDate
            body {
              raw
            }
          }
        }
      }
    }
  `);

  const itemsLists = data.allJsonJson.edges
    .filter(({ node }) => !!node.homePage)
    .find(({ node }) => !!node.homePage).node.homePage.sections;

  const articlesList = data.allContentfulArticle.edges.sort(
    ({ node: { publishedDate: a } }, { node: { publishedDate: b } }) => new Date(b) - new Date(a),
  );

  const renderInformationList = useCallback(({ id, list, ...rest }) => {
    const buttonProps = {
      color: 'black',
      title: 'Read more',
      isCustomButtonLink: true,
    };

    const defaultProps = {
      listCssRules: 'max-width: 1088px',
      buttonProps,
      list,
      ...rest,
    };

    return <InformationList {...defaultProps} />;
  }, []);

  const lists = useMemo(
    () => itemsLists && itemsLists.map(renderInformationList),
    [itemsLists],
  );

  const descriptionText = articlesList
    && getFirstParagraphText(articlesList[0]?.node?.body).slice(0, 160);

  return (
    <>
      <Helmet>
        <title>Home page | Depop newsroom</title>
        <meta name="description" content={descriptionText} />
        <meta itemProp="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta property="twitter:description" content={descriptionText} />
      </Helmet>
      <CarouselContainer carouselStepTime={6000} />
      <NewsContainer title="Latest news" />
      {lists}
    </>
  );
};

export default Home;
