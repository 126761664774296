import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { fonts, colors } from '@styles/variables';
import { Link } from 'gatsby';

const StyledNewsElement = styled.div`
  padding: 0 16px;

  ${screen.l(css`
    padding: 0 8px;
  `)}
`;

const StyledNewsImg = styled(Link)`
  position: relative;
  display: block;
  height: 236px;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    content: '';
    transition: background-color 0.15s;
  }

  &:hover {
    &::after {
      background: rgba(0, 0, 0, 0.25);
    }
  }

  img {
    display: block;
    width: 100%;
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }
`;

const StyledNewsDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.colorLiteGrey};

  p {
    margin: 10px;
    font: 14px/21px ${fonts.fontPrimaryRegular};
  }
`;

const StyledNewsLink = styled(Link)`
  color: ${colors.colorBlack};
  font: 14px/21px ${fonts.fontPrimaryBold};
  text-decoration: none;

  transition: color 0.15s;

  &:hover {
    color: ${colors.colorDoveGrey};
  }

  &:active {
    color: ${colors.colorDoveGrey};
    outline: none;
  }
`;

const StyledNewsTitle = styled.h3`
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 8px;
  -webkit-box-orient: vertical;
  font: 16px/24px ${fonts.fontPrimaryBold};
  -webkit-line-clamp: 6;

  a {
    color: black;
    text-decoration: none;
  }
`;

const StyledNewsText = styled.p`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font: 14px/21px ${fonts.fontPrimaryRegular};
  -webkit-line-clamp: 6;
  line-height: 21px;

  a {
    color: black;
    text-decoration: none;
  }
`;
export default {
  StyledNewsElement,
  StyledNewsImg,
  StyledNewsLink,
  StyledNewsTitle,
  StyledNewsText,
  StyledNewsDate,
};
