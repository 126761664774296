import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import AliceCarousel from 'react-alice-carousel';
import styled from 'styled-components';
import 'react-alice-carousel/lib/alice-carousel.css';
import RightArrow from '@assets/img/right-arrow.svg';
import { getImage } from 'gatsby-plugin-image';
import { getFirstSection } from '@utils/richText';

import {
  StyledNewsButton,
  StyledNewsContainer,
  StyledNewsHeader,
  StyledNewsTitle,
} from './NewsContainer.styles';
import NewsElement from '../NewsElement';
import { sortDescendingPublishedDate } from '../../utils/sort';

const StyledCarouselContainer = styled.div`
  width: 100%;
  max-width: 1312px;
  margin: 0 auto;

  .alice-carousel__stage {
    transition-duration: 300ms !important;
  }
`;

const NewsContainer = ({ title, backgroundClass }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulArticle(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            id
            title
            image {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
            updatedAt
            slug
            publishedDate
            subtitle {
              subtitle
            }
            body {
              raw
            }
            isFeatured
          }
        }
      }
    }
  `);

  const articlesData = data.allContentfulArticle.edges.map(({ node }) => node);

  const carouselItems = articlesData
    .filter(({ isFeatured }) => isFeatured)
    .sort(sortDescendingPublishedDate)
    .slice(3)
    .map(({ slug, title, image, body, publishedDate }) => {
      const headerImageUrl = getImage(image);
      const firstSection = getFirstSection(body);

      return (
        <NewsElement
          key={slug}
          id={slug}
          title={title}
          date={publishedDate}
          headerImageUrl={headerImageUrl}
          firstSection={firstSection}
        />
      );
    });

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    1440: { items: 4 },
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const handleArrows = useCallback(
    ({ isPrevSlideDisabled, isNextSlideDisabled }) => {
      if (isPrevSlideDisabled !== isPrevButtonDisabled) {
        setIsPrevButtonDisabled(isPrevSlideDisabled);
      }
      if (isNextSlideDisabled !== isNextButtonDisabled) {
        setIsNextButtonDisabled(isNextSlideDisabled);
      }
    },
    [
      isPrevButtonDisabled,
      isNextButtonDisabled,
      setIsPrevButtonDisabled,
      setIsNextButtonDisabled,
    ]
  );

  const onSlideChanged = ({ item, ...rest }) => {
    setActiveIndex(item);
    handleArrows(rest);
  };

  const onSlideChange = ({ item, ...rest }) => {
    handleArrows(rest);
  };

  const onResized = (props) => {
    handleArrows(props);
  };

  const carouselRef = useRef();
  const renderActionButton = () => <RightArrow />;

  const carousel = useMemo(
    () => (
      <StyledCarouselContainer>
        <AliceCarousel
          mouseTracking
          disableDotsControls
          disableButtonsControls
          activeIndex={activeIndex}
          items={carouselItems}
          responsive={responsive}
          renderPrevButton={renderActionButton}
          renderNextButton={renderActionButton}
          onSlideChanged={onSlideChanged}
          onSlideChange={onSlideChange}
          onResized={onResized}
          ref={carouselRef}
        />
      </StyledCarouselContainer>
    ),
    [
      carouselRef,
      activeIndex,
      carouselItems,
      responsive,
      renderActionButton,
      onSlideChanged,
      onSlideChange,
      onResized,
    ]
  );

  return (
    <StyledNewsContainer className={backgroundClass}>
      <StyledNewsHeader>
        <StyledNewsButton
          aria-label="left arrow"
          disabled={isPrevButtonDisabled}
          onClick={() => carouselRef.current.slidePrev()}
        >
          <RightArrow />
        </StyledNewsButton>
        <StyledNewsTitle>{title}</StyledNewsTitle>
        <StyledNewsButton
          disabled={isNextButtonDisabled}
          aria-label="right arrow"
          onClick={() => carouselRef.current.slideNext()}
        >
          <RightArrow />
        </StyledNewsButton>
      </StyledNewsHeader>
      {carousel}
    </StyledNewsContainer>
  );
};

NewsContainer.defaultProps = {
  backgroundClass: '',
  title: '',
};

NewsContainer.propTypes = {
  backgroundClass: PropTypes.string,
  title: PropTypes.string,
};

export default NewsContainer;
