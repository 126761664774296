import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import ClockIcon from '@icons/clock.svg';
import { screen } from '@styles/mixins/breakpoints';
import { colors, fonts } from '@styles/variables';
import Button from '../Button';

const StyledContainer = styled.div`
  display: flex;
  height: 565px;

  ${screen.l(css`
    width: 100%;
    height: auto;
    flex-direction: column;
  `)}
`;

const StyledNewsImageLink = styled(Link)`
  display: flex;
  width: 50%;
  min-width: 50%;
  height: 100%;
  position: relative;

  &:focus {
    box-shadow: none;
    outline: none;
    &::after {
      box-shadow: inset -2px -2px 0px ${colors.colorCeruleanBlue},
        inset 2px 2px 0px ${colors.colorCeruleanBlue},
        inset -3px -3px ${colors.colorWhite}, inset 3px 3px ${colors.colorWhite};
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  ${screen.l(css`
    width: 100%;
    height: 400px;
  `)}

  ${screen.sm(css`
    width: 100%;
    height: 100vw;
  `)}
`;

const StyledNewsImage = styled(GatsbyImage)`
  width: 100%;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledNewsContainer = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  box-sizing: border-box;

  flex-direction: column;

  padding-top: 90px;
  padding-right: 190px;
  padding-left: 64px;
  background: ${colors.colorScarlet};
  color: ${colors.colorWhite};

  @media (max-width: 1230px) {
    width: 100%;

    min-height: 400px;
    padding-top: 74px;
    padding-right: 64px;
    padding-bottom: 50px;
  }
  ${screen.tablet(css`
    padding-top: 62px;
    padding-right: 82px;
    padding-left: 32px;
  `)}
  ${screen.l(css`
    padding-right: 110px;
    padding-left: 52px;
  `)}

  ${screen.sm(css`
    padding-right: 32px;
    padding-left: 16px;
  `)}
`;

const StyledArticleTitleNotesDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-right: 8px;
    fill: ${colors.colorWhite};
  }

  ${screen.l(css`
    margin-bottom: 10px;
  `)}
`;

const StyledNewsTitle = styled.h1`
  width: 480px;
  margin-bottom: 16px;
  font: 32px/48px ${fonts.fontSecondaryBold};

  @media (max-width: 1230px) {
    width: 100%;
  }

  ${screen.l(css`
    margin-bottom: 10px;
  `)}
  ${screen.sm(css`
    font-size: 24px;
  `)}
`;

const StyledNewsTitleLink = styled(Link)`
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: white;
  -webkit-line-clamp: 4;
  text-decoration: none;
`;

const StyledTrimmedContent = styled.p`
  display: -webkit-box;
  overflow: hidden;
  width: 480px;
  margin-bottom: 32px;
  -webkit-box-orient: vertical;
  font: 16px/24px ${fonts.fontPrimaryRegular};
  -webkit-line-clamp: 2;

  @media (max-width: 1230px) {
    width: 100%;
    -webkit-line-clamp: 2;
  }

  ${screen.l(css`
    margin-bottom: 23px;
    -webkit-line-clamp: 3;
  `)} ${screen.sm(css`
    font-size: 16px;
  `)} a {
    color: ${colors.colorWhite};
    text-decoration: none;
  }
`;

const StyledDotsContainer = styled.div`
  position: absolute;
  bottom: 51px;
  left: 64px;
  display: flex;
  align-items: center;

  ${screen.tablet(css`
    left: 32px;
  `)}

  ${screen.l(css`
    top: 30px;
    left: 50%;
    height: 14px;
    transform: translateX(-50%);
  `)}
`;

const StyledDotWrapper = styled.button`
  display: flex;
  width: 14px;
  height: 14px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: 100%;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const StyledDotIndication = styled.div`
  width: ${({ isActive }) => (isActive ? 14 : 10)}px;

  height: ${({ isActive }) => (isActive ? 14 : 10)}px;

  background: ${({ isActive }) =>
    isActive ? colors.colorWhite : colors.colorMutedWhite};
  border-radius: 100%;
  outline: none;
`;
/**
 * Carousel with the latest news
 */
const Carousel = ({ article, articles, onChangeArticle }) => {
  const { title, description, date, imageUrl = '', subTitle } = article;
  const creationDate = moment(date).format('D MMM YYYY');

  return (
    <StyledContainer>
      <StyledNewsImageLink to={`/${article.id}/`}>
        <StyledNewsImage
          image={imageUrl}
          backgroundColor="#EEEEEE"
          loading="lazy"
          title={title}
          alt={title}
        />
      </StyledNewsImageLink>
      <StyledNewsContainer>
        {!!creationDate && (
          <StyledArticleTitleNotesDate>
            <ClockIcon />
            <span>{creationDate}</span>
          </StyledArticleTitleNotesDate>
        )}
        <StyledNewsTitle aria-live="polite">
          <StyledNewsTitleLink to={`/${article.id}/`}>
            {title}
          </StyledNewsTitleLink>
        </StyledNewsTitle>
        <StyledTrimmedContent dangerouslySetInnerHTML={{ __html: subTitle }} />
        <Button
          to={`/${article.id}/`}
          color="black"
          title="Read more"
          cssRules={css`
            width: 138px;
          `}
        />
        <StyledDotsContainer>
          {articles.map(({ id, title }) => {
            const handleOnChangeArticle = () => {
              onChangeArticle(id);
            };
            return (
              <StyledDotWrapper
                title={`Check ${title} article slide`}
                aria-label={`Check ${title} article slide`}
                onClick={handleOnChangeArticle}
                key={id}
              >
                <StyledDotIndication isActive={id === article.id} />
              </StyledDotWrapper>
            );
          })}
        </StyledDotsContainer>
      </StyledNewsContainer>
    </StyledContainer>
  );
};

Carousel.propTypes = {
  article: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.shape(),
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
  }).isRequired,
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      description: PropTypes.string,
      id: PropTypes.string.isRequired,
      imageUrl: PropTypes.shape(),
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChangeArticle: PropTypes.func.isRequired,
};

export default Carousel;
