import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { colors, fonts } from '@styles/variables';

export const StyledNewsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 91px 16px 85px;

  &.bg-grey {
    background-color: ${colors.colorGallery};
  }
  ${screen.l(css`
    padding: 45px 8px;
  `)}
`;

export const StyledNewsHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: 1312px;
  align-items: center;
  justify-content: space-between;
  margin: auto auto 95px;
  ${screen.l(css`
    margin-bottom: 45px;
  `)}
`;

export const StyledNewsButton = styled.button`
  border: none;
  background: none;
  outline: none;
  ${({ disabled }) => !disabled
    && css`
      cursor: pointer;

      &:hover svg path {
        fill: ${colors.colorDoveGrey};
        stroke: ${colors.colorDoveGrey};
      }
    `}
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
  
  svg {
    width: 50px;
    height: 44px;
  }

  &:nth-child(1) {
    transform: rotate(180deg);
  }
`;

export const StyledNewsTitle = styled.h2`
  padding: 0 24px;
  color: ${colors.colorBlack};
  font-family: ${fonts.fontSecondaryBold};
  font-size: 30px;
  line-height: 36px;
  text-align: center;

  ${screen.l(css`
    font-size: 24px;
  `)}
`;

export const StyledNewsBody = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin: 30px auto;
`;
